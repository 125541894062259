/* Toasts styles */
.root {
  width: auto !important;
  min-width: 375px !important;
  z-index: 9999999999 !important;
  @media only screen and (max-width : 480px) {
    width: 100vw !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.toastWrapper {
  @apply border border-solid text-white rounded-[10px] backdrop-blur-md py-[9px] min-h-0 px-3 #{!important};

  z-index: 9999999999;

  background-color: rgba(255, 255, 255, 0.04) !important;
  border-color: rgba(255, 255, 255, 0.1) !important;

  @media only screen and (max-width : 480px) {
    @apply rounded-none m-0 #{!important};

    border-left: none !important;
    border-right: none !important;
  }
}

.toastBody {
  @apply flex items-center flex-auto #{!important};
  @apply m-0 pl-0 pr-[10px] pb-0 pt-0 leading-5 #{!important};

  &:global {
    & > .Toastify__toast-icon {
      @apply mr-[10px] #{!important};
    }
  }
}

.successIcon {
  color: #00CCAA;
  box-shadow: 0 0 14px rgba(0, 204, 170, 0.5);
  border-radius: 50%;
}

.errorIcon {
  color: #FF504C;
  box-shadow: 0 0 14px rgba(255, 80, 77, 0.5);
  border-radius: 50%;
}

.closeButton {
  @apply appearance-none flex items-center text-[15px];

  color: #F0F1F4;
}
